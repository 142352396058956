<template>
<div class="content">
    <div class="topMess">
        <div class="messDetail">
            <HeadTop />
            <span style="margin-left:10px">></span>
            <span style="margin-left:10px">{{title}}</span>
        </div>
    </div>
    <div class="video">
        <!-- <div class="videoDetail" v-if="videoMess.playbacks.length==0">
            <a-empty style="margin-top:100px;"></a-empty>
        </div> -->
        <div class="videoDetail">
            <iframe id="full_screen" ref="iframe" scrolling="auto" width="100%" height="100%" frameborder="0"></iframe>
        </div>
        <div class="detailMess">
                <div class="title">{{videoMess.title}}</div>
                <a-row class="detail">
                    <a-col class="detailLeft" :span="2">活动简介</a-col>
                    <a-col class="detailRight" :span="17">{{videoMess.liveContent || '暂无信息'}}</a-col>
                </a-row>
                <a-row class="detail">
                    <a-col class="detailLeft" :span="2">讲师</a-col>
                    <a-col class="detailRight" :span="17">{{videoMess.liveTeacherName}} {{videoMess.liveTeacherInfo || '暂无信息'}}</a-col>
                </a-row>
                <ul class="videoList" v-if="videoMess.playbacks!=null">
                    <li class="listLi1"><span>视频列表</span> <span>({{indexNum}}/{{totalNum}})</span></li>
                    <li :class="index==indexNum-1?'activeLi':'listLi'" v-for="(i,index) in videoMess.playbacks" :key="index" @click="watchVideo(i,index)">
                        <img v-if="showPlayImg=index==indexNum-1?true:false" class="playImg" src="../../assets/img/092.png" alt="" />
                        <img v-if="showPlayImg=index==indexNum-1?false:true" class="playImg" src="../../assets/img/102.png" alt="" />
                        <span class="name">{{i.name}}</span>
                    </li>
                </ul>
                <!-- <a-button @click="fullScreen">测试</a-button> -->
        </div>
    </div>
</div>
</template>

<script>
import HeadTop from '@/components/HeadTop.vue'
import format from '@/utils/format.js'
import screenfull from 'screenfull'
export default {
    name: 'activeVideo',
    data() {
        return {
            format,
            indexNum: 1, //默认为1,
            totalNum: 0,
            videoMess: '',
            title: '',
            showPlayImg: false
        }
    },
    watch:{
        $route(){
            // console.log(this.$route)
        }
    },
    mounted() {
        this.getMess()
    },
    methods:{
        getMess(){
            this.videoMess = JSON.parse(sessionStorage.getItem('videoDetail'))
            // this.videoMess = this.$route.params.item
            this.title = this.videoMess.title
            this.totalNum = this.videoMess.playbacks.length
            if(this.videoMess.playbacks.length>0){
                this.$refs.iframe.contentWindow.location.replace(this.videoMess.playbacks[0].playUrl)
            }else{
                this.$refs.iframe.$el.style.display = 'none'
            }
        },
        watchVideo(i,index){
            this.showPlayImg = true
            this.indexNum = index + 1
            this.$refs.iframe.contentWindow.location.replace(i.playUrl)
        },
        fullScreen(){
            const element = document.getElementById('full_screen')
                        screenfull.request(element)
                        // console.log(this.$refs.iframe.contentWindow)
        }
    },
    components:{
        HeadTop
    }
}
</script>

<style lang="less" scoped>
    ul,li{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .content{
        background: #fff;
        .topMess{
            background: #f6f6f6;
            height: 60px;
            line-height: 60px;
            width: 100%;
            .messDetail{
                width: 1140px;
                margin: 0 auto;
                height: 60px;
                line-height: 60px;
                display: flex;
            }
        }
        .video{
            min-height:650px;
            display: flex;
            width: 1140px;
            margin: 0 auto;
            padding: 50px 0px;
            .videoDetail{
                width: 75%;
                height: 100%;
                height: 650px;
            }
            .detailMess{
                width: 25%;
                height: 100%;
                padding: 30px 0px 30px 40px;
                .title{
                    font-weight: bold;
                    color: #000000;
                    line-height: 30px;
                    font-size: 18px;
                }
                .detail{
                    margin-top: 10px;
                    .detailLeft{
                        width:80px;
                        height: 22px;
                        line-height: 22px;
                        background: #fbeeec;
                        border-radius: 12px;
                        color:#DB5647;
                        font-size: 14px;
                        text-align: center;
                    }
                    .detailRight{
                        padding-left: 10px;
                        color: #000;
                        font-size: 12px;
                        padding-top: 8px;
                         height: 22px;
                        line-height: 22px;
                    }
                }
                .videoList{
                    border: 1px solid #ccc;
                    color:#000;
                    font-size: 12px;
                    margin-top: 20px;
                    cursor: pointer;
                    .listLi1,.listLi{
                        width: 100%;
                        line-height: 30px; 
                        padding: 0px 10px;
                    }
                    .listLi1{
                        background: #FFF;
                    }
                    .listLi{
                        background: #F6F6F6;
                        border-top: 1px solid #ccc ;
                    }
                    .playImg{
                        width: 12px;
                        height: 12px;
                        margin-top: -5px;
                        margin-right: 3px;
                    }
                }
            }
        }
    }
    .activeLi{
        color:#DB5647;
        width: 100%;
        line-height: 30px; 
        padding: 0px 10px;
        border-top: 1px solid #ccc 
    }
</style>